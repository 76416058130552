import { lazy } from "react"
import { createHashRouter } from "react-router-dom"

import { ErrorBoundary } from "@/components/ErrorBoundary"
import { MobileRedirect } from "@/components/MobileRedirect/MobileRedirect"
import {
    DisclaimerInner,
    DisclaimerPage,
    EULAInner,
    EULAPage,
    PrivacyPolicyInner,
    PrivacyPolicyPage,
    TermsOfUseInner,
    TermsOfUsePage,
} from "@/pages/terms-and-policy/Terms"

const isProduction = import.meta.env.VITE_ENVIRONMENT_NAME === "production"

const Layout = lazy(() => import("@/template/layout"))
const DashboardPage = lazy(() => import("@/pages/dashboard"))
const ChatPage = lazy(() => import("@/pages/chat"))
const WorkflowsPage = lazy(() => import("@/pages/workflows"))
const WorkflowDetailsPage = lazy(() => import("@/pages/workflowDetails"))
const UploadPage = lazy(() => import("@/pages/upload"))
const DocumentsPage = lazy(() => import("@/pages/documents"))
const ConversationsPage = lazy(() => import("@/pages/conversations"))
const SettingsPage = lazy(() => import("@/pages/settings"))
const DocumentDetailsPage = lazy(() => import("@/pages/documentDetails"))
const UserInfoPage = lazy(() => import("@/pages/userInfoPage"))
const NoPage = lazy(() => import("@/pages/NoPage"))
const MobileOopsPage = lazy(() => import("@/pages/MobileOopsPage"))
const ConnectorsSetupPage = lazy(() => import("@/pages/connectors"))
const HelpAndResources = lazy(() => import("@/pages/helpAndResources"))
const ChatProvider = lazy(() => import("@/components/NotificationCenter/ChatWebPubSubProvider"))
const NewsPage = lazy(() => import("@/pages/news"))
const DebugWelcomeModal = isProduction
    ? lazy(() => import("@/pages/NoPage"))
    : lazy(() => import("@/test/DebugWelcomeModal"))

export enum RoutesPath {
    Root = "/",
    Chat = "/chat",
    Workflows = "/workflows",
    WorkflowsDetails = "/workflow-details",
    ChatId = "/chat/:id",
    Upload = "/upload",
    Documents = "/documents",
    DocumentDetails = "/documents/:id?/:name?",
    Conversations = "/conversations",
    Settings = "/settings",
    User = "/user",
    Connectors = "/connectors",
    TermsOfUse = "/terms-of-service",
    TermsOfService = "/terms-of-use",
    PrivacyPolicy = "/privacy-policy",
    Disclaimer = "/disclaimer",
    EULA = "/eula",
    PrivacyPolicyInner = "/privacy-policy-inner",
    EULAInner = "/eula-inner",
    DisclaimerInner = "/disclaimer-inner",
    TermsOfUseInner = "/terms-of-use-inner",
    HelpAndResources = "/help-and-resources",
    News = "/announcements",
    DebugModal = "/debug-modal",
    NoPage = "*",
}

const router = createHashRouter([
    {
        element: (
            <ErrorBoundary>
                <Layout />
                <MobileRedirect />
            </ErrorBoundary>
        ),
        path: RoutesPath.Root,
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },
            {
                path: RoutesPath.Chat,
                element: (
                    <ChatProvider>
                        <ChatPage />
                    </ChatProvider>
                ),
                children: [
                    {
                        path: RoutesPath.ChatId,
                        element: (
                            <ChatProvider>
                                <ChatPage />
                            </ChatProvider>
                        ),
                    },
                ],
            },
            {
                path: RoutesPath.Workflows,
                element: <WorkflowsPage />,
            },
            {
                path: RoutesPath.WorkflowsDetails,
                element: <WorkflowDetailsPage />,
            },
            {
                path: RoutesPath.Upload,
                element: <UploadPage />,
            },
            {
                path: RoutesPath.Documents,
                element: <DocumentsPage />,
            },
            {
                path: RoutesPath.DocumentDetails,
                element: <DocumentDetailsPage />,
            },
            {
                path: RoutesPath.Conversations,
                element: <ConversationsPage />,
            },
            {
                path: RoutesPath.Settings,
                element: <SettingsPage />,
            },
            {
                path: RoutesPath.User,
                element: <UserInfoPage />,
            },
            {
                path: RoutesPath.NoPage,
                element: <NoPage />,
            },
            // {
            //     path: RoutesPath.Subscriptions,
            //     element: <SubscriptionsPage />,
            // },
            {
                path: RoutesPath.Connectors,
                element: <ConnectorsSetupPage />,
            },
            {
                path: RoutesPath.PrivacyPolicyInner,
                element: <PrivacyPolicyInner />,
            },
            {
                path: RoutesPath.EULAInner,
                element: <EULAInner />,
            },
            {
                path: RoutesPath.DisclaimerInner,
                element: <DisclaimerInner />,
            },
            {
                path: RoutesPath.TermsOfUseInner,
                element: <TermsOfUseInner />,
            },
            {
                path: RoutesPath.HelpAndResources,
                element: <HelpAndResources />,
            },
            {
                path: RoutesPath.News,
                element: <NewsPage />,
            },
            {
                path: RoutesPath.DebugModal,
                element: <DebugWelcomeModal />,
            },
        ],
    },
    {
        path: "/mobile-oops",
        element: <MobileOopsPage />,
    },
    {
        path: RoutesPath.TermsOfUse,
        element: <TermsOfUsePage />,
    },
    {
        path: RoutesPath.TermsOfService,
        element: <TermsOfUsePage />,
    },
    {
        path: RoutesPath.PrivacyPolicy,
        element: <PrivacyPolicyPage />,
    },
    {
        path: RoutesPath.Disclaimer,
        element: <DisclaimerPage />,
    },
    {
        path: RoutesPath.EULA,
        element: <EULAPage />,
    },
])

export default router
