import { downloadFile, getFileInfo } from "@/api"
import { toast } from "react-toastify"
import axios, { AxiosProgressEvent } from "axios"
import { t } from "i18next"
import { ModalContextProps } from "antd/es/modal/context"
import { languageMap } from "@/components/CitationPDFViewer/PDFTranslation"

interface TranslationOptions {
    page?: number
    forceTranslate?: boolean
    forceTranslateFull?: boolean
    timeout?: number
}

interface TranslatedFileResult {
    data: ArrayBuffer
    contentType: string
    filename: string
}

export async function getTranslatedFileBytes(
    fileId: string,
    targetLanguage: string,
    totalPages: number,
    download_source: boolean,
    options: TranslationOptions = {}
): Promise<TranslatedFileResult> {
    const endpoint =
        options.page !== undefined && options.page !== null
            ? `/files/download_translated_page/${fileId}/${options.page + 1}/${totalPages}/${targetLanguage}`
            : `/files/download_translated/${fileId}/${totalPages}/${targetLanguage}`
    // console.log("getTranslatedFileBytes", "download_source", download_source, "options", options, endpoint)
    try {
        const response = await axios.get(endpoint, {
            responseType: "arraybuffer",
            params: {
                force_translate: options.forceTranslate,
                force_translate_full: options.forceTranslateFull,
                download_source: download_source,
            },
            timeout: options.timeout || (options.page ? 120000 : 300000),
        })

        const contentType = response.headers["content-type"]
        if (contentType?.includes("application/json")) {
            const decoder = new TextDecoder("utf-8")
            const jsonData = JSON.parse(decoder.decode(response.data))
            throw new Error(jsonData.detail || "Unexpected response from server")
        }

        if (response.status !== 200) {
            throw new Error(`Request failed with status ${response.status}`)
        }

        const contentDisposition = response.headers["content-disposition"]
        let filename = ""
        if (contentDisposition) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)
            if (matches?.[1]) {
                filename = matches[1].replace(/['"]/g, "")
            }
        }

        return {
            data: response.data,
            contentType,
            filename,
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 402) {
                throw new Error("Translation limit reached")
            }
            throw new Error(`Translation failed: ${error.message}`)
        }
        throw error
    }
}

export const onDownload = (options: {
    path: string
    forceFilename?: boolean
    fileId: string
    downloadTranslater?: boolean
    translateTo?: string
    translateImages?: boolean
    currentPageOnly?: boolean
    page?: number
    totalPages?: number
    downloadSource: boolean
    confirm: (message: string, props: ModalContextProps) => void
}) => {
    const {
        path,
        fileId,
        downloadSource = false,
        forceFilename = false,
        downloadTranslater = false,
        translateTo = "en",
        translateImages = false,
        currentPageOnly = false,
        page = false,
        totalPages = 1,
        confirm,
    } = options

    const toastId = toast.loading("Downloading...0%")

    const file = getFileInfo(fileId, null)

    file.then(res => {
        const totalFileSize = res.file_size ? res.file_size : 0

        const progressEvent = (event: AxiosProgressEvent) => {
            toast.update(toastId, {
                render: `Downloading...${Math.round((event.loaded / totalFileSize) * 100)}%`,
            })
        }

        const download = (fileId: string, path: string, downloadSource: boolean) => {
            const promise = downloadFile(fileId, progressEvent, path, downloadSource)

            promise
                .then(({ blob, filename }) => {
                    let fileName: string

                    if (forceFilename) {
                        const extension = path.split(".").pop()
                        fileName = `${path}.${translateTo}.${extension}`
                    } else {
                        fileName = filename
                    }

                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement("a")
                    link.href = url
                    link.setAttribute("download", fileName)
                    link.style.display = "none"
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                    URL.revokeObjectURL(url)

                    toast.update(toastId, {
                        render: `Downloaded ${fileName}`,
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        draggable: true,
                    })
                })
                .catch(error => {
                    toast.update(toastId, {
                        render: `Failed to download ${path}`,
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                    })
                })
        }

        if (downloadTranslater) {
            interface HandleTranslationOptions {
                forceTranslate?: boolean
                forceTranslateFull?: boolean
                page?: number | undefined
            }

            const handleTranslation = async ({
                page,
                forceTranslate,
                forceTranslateFull,
            }: HandleTranslationOptions) => {
                try {
                    const {
                        data,
                        contentType,
                        filename: serverFilename,
                    } = await getTranslatedFileBytes(fileId, translateTo, totalPages, downloadSource, {
                        page,
                        forceTranslate,
                        forceTranslateFull,
                    })

                    let fileName: string

                    if (forceFilename) {
                        const extension = path.split(".").pop()
                        const pathWithoutExtension = path.replace(`.${extension}`, "")
                        const pageStr = currentPageOnly ? `_${page! + 1}` : ""

                        fileName = `${pathWithoutExtension}${pageStr}.${translateTo}.${extension}`
                    } else {
                        fileName = serverFilename || path
                    }

                    const blob = new Blob([data], { type: contentType })
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement("a")
                    link.href = url
                    link.setAttribute("download", fileName)
                    link.style.display = "none"
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                    URL.revokeObjectURL(url)

                    toast.update(toastId, {
                        render: `Downloaded translated ${fileName}`,
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        draggable: true,
                    })
                } catch (error: any) {
                    if (error.message === "File is already in the target language") {
                        confirm(
                            t("This file is already in {{targetLanguage}}. Do you want to proceed anyway?", {
                                targetLanguage: languageMap[translateTo],
                            }),
                            {
                                onOk: async () => {
                                    await handleTranslation({
                                        forceTranslate: true,
                                        forceTranslateFull: true,
                                        page: currentPageOnly ? page : undefined,
                                    })
                                },
                                onCancel: () => {
                                    toast.update(toastId, {
                                        render: "Translation cancelled",
                                        type: "info",
                                        isLoading: false,
                                        autoClose: 3000,
                                    })
                                },
                            }
                        )
                    } else if (error.message === "Are you sure you want to translate all pages?") {
                        confirm(t("Are you sure you want to translate all {{num}} pages?", { num: totalPages }), {
                            onOk: async () => {
                                console.log("Forcing full translation")
                                await handleTranslation({
                                    forceTranslateFull: true,
                                    page: currentPageOnly ? page : undefined,
                                })
                            },
                            onCancel: () => {
                                toast.update(toastId, {
                                    render: "Translation cancelled",
                                    type: "info",
                                    isLoading: false,
                                    autoClose: 3000,
                                })
                            },
                        })
                    } else if (error.message === "Translation limit reached") {
                        toast.update(toastId, {
                            render: "Translation limit reached. Please upgrade your plan.",
                            type: "error",
                            isLoading: false,
                            autoClose: false,
                        })
                    } else {
                        toast.update(toastId, {
                            render: `Failed to translate ${path}: ${error.message}`,
                            type: "error",
                            isLoading: false,
                            autoClose: 3000,
                        })
                    }
                }
            }

            handleTranslation({ page: currentPageOnly && typeof page === "number" ? page : undefined })
        } else {
            download(fileId, path, downloadSource)
        }
    }).catch(error => {
        toast.update(toastId, {
            render: "Failed to get file info",
            type: "error",
            isLoading: false,
            autoClose: 3000,
        })
    })
}
