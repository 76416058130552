import React, { useEffect, useState } from "react"
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material"
import theme from "@/theme"
import { languages } from "../Translate/Translate"
import { useDispatch, useSelector } from "react-redux"
import { setLatestTranslationLanguage } from "@/slices/chatSlice"

export const TranslateDownloadModal = ({
    open,
    onClose,
    onConfirm,
    isSourceFilePDF = false,
    onTranslateAndDisplay,

    fileName,
    currentPage,
    totalPages,
}: {
    open: boolean
    onClose: () => void
    onConfirm: (options: {
        translateTo: string
        translateImages: boolean
        currentPageOnly: boolean
        translateSourceFile: boolean
    }) => void
    onTranslateAndDisplay: (options: {
        translateTo: string
        currentPageOnly: boolean
        translateSourceFile: boolean
    }) => void
    isSourceFilePDF?: boolean
    currentPage: React.MutableRefObject<number>
    fileName: string
    totalPages: number
}) => {
    const { latestTranslationLanguage } = useSelector((state: any) => state.chat)
    const dispatch = useDispatch()

    const [translateTo, setTranslateTo] = useState(latestTranslationLanguage)
    const translateImages = false
    const [currentPageOnly, setCurrentPageOnly] = useState(false)
    const [translateSourceFile, setTranslateSourceFile] = useState(true)

    const fileExtension = fileName.split(".").slice(-2, -1)[0]
    const translatableList = [
        "doc",
        "docx",
        "dot",
        "odt",
        "xls",
        "xlsx",
        "ods",
        "csv",
        "ppt",
        "pptx",
        "odp",
        "html",
        "htm",
        "txt",
        "csv",
    ]
    const sourceTranslatable = translatableList.includes(fileExtension)

    useEffect(() => {
        if (isSourceFilePDF || !sourceTranslatable) {
            setTranslateSourceFile(false)
        }
    }, [isSourceFilePDF, sourceTranslatable])

    const handleDownload = () => {
        onConfirm({
            translateTo,
            translateImages,
            currentPageOnly,
            translateSourceFile,
        })
        onClose()
    }
    const handleTranslateAndDisplay = () => {
        onTranslateAndDisplay({ translateTo, currentPageOnly, translateSourceFile })
        onClose()
    }
    const handleLanguageChange = (e: string) => {
        setTranslateTo(e)
        dispatch(setLatestTranslationLanguage(e))
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            sx={{
                "& .MuiDialog-paper": {
                    width: "550px",
                },
            }}>
            <DialogTitle mt={0}>Translate Document</DialogTitle>
            <DialogContent>
                {sourceTranslatable && !isSourceFilePDF && (
                    <Box
                        sx={{
                            marginLeft: "0px",
                            mt: 0,
                            borderBottom: 2,
                            borderColor: "divider",
                            marginRight: "1px",
                        }}>
                        <Tabs
                            value={translateSourceFile ? 0 : 1}
                            onChange={(e, newValue) => setTranslateSourceFile(newValue === 0)}
                            variant="fullWidth"
                            centered
                            sx={{
                                "& .MuiTabs-indicator": {
                                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                                },
                                "& .MuiTab-root": {
                                    textTransform: "none",
                                    fontFamily: "Comfortaa",
                                    lineHeight: "1.5",
                                },
                            }}>
                            <Tooltip
                                title={"Use original file. Does not process images. Faster translation."}
                                placement="top">
                                <Tab
                                    label={
                                        <div>
                                            <div>Translate to .{fileExtension}</div>
                                        </div>
                                    }
                                />
                            </Tooltip>
                            <Tooltip title={"Use processed PDF. Translates images. Takes more time."} placement="top">
                                <Tab
                                    label={
                                        <div>
                                            <div>Translate&nbsp;to&nbsp;.pdf</div>
                                            <div>(include images translation)</div>
                                        </div>
                                    }
                                />
                            </Tooltip>
                        </Tabs>
                    </Box>
                )}
                <Tooltip title="Choose the language you want to translate your document to" placement="top">
                    <FormControl fullWidth sx={{ mt: sourceTranslatable ? 5 : 3 }}>
                        <InputLabel id="language-select">Select Target Language</InputLabel>
                        <Select
                            labelId="language-select"
                            value={translateTo}
                            label="Select Target Language"
                            onChange={e => handleLanguageChange(e.target.value as string)}
                            renderValue={selected => {
                                const selectedLang = languages.find(lang => lang.code === selected)
                                if (!selectedLang) {
                                    return <Typography variant="body1">Select a language</Typography>
                                }
                                return (
                                    <Box display="flex" alignItems="center">
                                        <img
                                            src={selectedLang.flag}
                                            alt={selectedLang.code}
                                            className="language__img w-6 h-4 mr-3 object-cover"
                                            style={{ marginRight: "10px" }}
                                        />
                                        <Typography variant="body1" fontSize="16px" fontFamily="Comfortaa">
                                            {selectedLang.name}
                                        </Typography>
                                    </Box>
                                )
                            }}>
                            {languages.map(lang => (
                                <MenuItem key={lang.code} value={lang.code}>
                                    <Box display="flex" alignItems="center">
                                        <img
                                            src={lang.flag}
                                            alt={lang.code}
                                            className="language__img w-6 h-4 mr-3 object-cover"
                                            style={{ marginRight: "10px" }}
                                        />
                                        <Typography variant="body1" fontSize="16px" fontFamily="Comfortaa">
                                            {lang.name}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Tooltip>

                {totalPages > 1 && (
                    <Tooltip
                        title={
                            translateSourceFile
                                ? "Page selection available for .pdf translations"
                                : "Translate only the currently visible page"
                        }
                        placement="top">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!translateSourceFile && currentPageOnly}
                                    onChange={e => setCurrentPageOnly(e.target.checked)}
                                    disabled={translateSourceFile}
                                />
                            }
                            label={`Translate current page only${translateSourceFile ? " – Disabled for source files" : ` (Page ${currentPage.current + 1})`}`}
                            sx={{ mt: 2, width: "95%" }}
                        />
                    </Tooltip>
                )}
            </DialogContent>
            <DialogActions sx={{ paddingBottom: 3, paddingTop: 2, paddingRight: 3 }}>
                <Button variant="outlined" color="error" onClick={onClose}>
                    Cancel
                </Button>
                <>
                    <Button onClick={handleTranslateAndDisplay} variant="contained">
                        Show on aidocr
                    </Button>
                    <Button onClick={handleDownload} variant="contained" style={{ marginLeft: "10px" }}>
                        Download
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    )
}
