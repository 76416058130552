import React, { useState } from "react"
import { TranslateCookieHandler } from "@/components/Translate/google-translate"
import { Button, Menu, MenuItem, Typography } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import LanguageIcon from "@mui/icons-material/Language"
import { t } from "@/i18n"

interface Language {
    code: string
    name: string
    flag: string
}

export const languages: Language[] = [
    { code: "en", name: "English", flag: "/images/lang/lang__en.png" },
    { code: "de", name: "Deutsch", flag: "/images/lang/lang__de.png" },
    { code: "fr", name: "Français", flag: "/images/lang/lang__fr.png" },
    { code: "es", name: "Español", flag: "/images/lang/lang__es.png" },
    { code: "it", name: "Italiano", flag: "/images/lang/lang__it.png" },
    { code: "sv", name: "Svenska", flag: "/images/lang/lang__sv.png" },
    { code: "ja", name: "日本語", flag: "/images/lang/lang__ja.png" },
]

export const LanguageSelector: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleLanguageChange = (lang: Language): void => {
        setAnchorEl(null)
        TranslateCookieHandler(`/auto/${lang.code}`)
        window.location.reload()
    }

    return (
        <div className="relative notranslate">
            <Button
                onClick={e => setAnchorEl(e.currentTarget)}
                className="notranslate flex items-center gap-2 text-primary hover:bg-action-hover">
                <LanguageIcon sx={{ marginRight: "10px" }} />
                <Tooltip title="Change language" placement="bottom">
                    <Typography variant="body1" fontSize="16px" fontFamily="Comfortaa" color="black" marginTop="1px">
                        {t("Language")}
                    </Typography>
                </Tooltip>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                className="notranslate language">
                {languages.map(lang => (
                    <MenuItem
                        key={lang.code}
                        onClick={() => handleLanguageChange(lang)}
                        className="notranslate menu-item">
                        <img
                            src={lang.flag}
                            alt={lang.code}
                            data-google-lang={lang.code}
                            className="language__img w-6 h-4 mr-3 object-cover"
                            style={{ marginRight: "10px" }}
                        />
                        <Typography variant="body1" fontSize="16px" fontFamily="Comfortaa">
                            {t(lang.name)}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}
//
// interface Translations {
//     [lang: string]: {
//         [key: string]: string
//     }
// }
//
// const getCurrentLanguage = (): string =>
//     document.cookie
//         .split("; ")
//         .find(row => row.startsWith("googtrans"))
//         ?.split("/")[2] || "en"
//
// export const useTranslations = (jsonPath: string) => {
//     const [translations, setTranslations] = useState<Translations>({})
//     const observerRef = useRef<MutationObserver | null>(null)
//     const timeoutRef = useRef<number | null>(null)
//     const lang = useRef(getCurrentLanguage())
//
//     useEffect(() => {
//         console.log("Loading translations")
//         fetch(jsonPath)
//             .then(response => response.json())
//             .then(data => setTranslations(data))
//             .catch(error => console.error("Error loading translations:", error))
//     }, [jsonPath])
//
//     const translateElement = useCallback(
//         (element: Element) => {
//             const key = element.getAttribute("data-i18n")
//             if (key && translations[lang.current] && translations[lang.current][key]) {
//                 element.textContent = translations[lang.current][key]
//             }
//         },
//         [translations]
//     )
//
//     const applyTranslations = useCallback(() => {
//         console.log("Applying translations")
//         document.querySelectorAll("[data-i18n]").forEach(element => {
//             translateElement(element)
//         })
//     }, [translateElement])
//
//     useEffect(() => {
//         console.log("Setting up translations")
//
//         const observerCallback = (mutations: MutationRecord[]) => {
//             mutations.forEach(mutation => {
//                 if (mutation.type === "childList") {
//                     mutation.addedNodes.forEach(node => {
//                         if (node instanceof Element) {
//                             if (node.hasAttribute("data-i18n")) {
//                                 translateElement(node)
//                             }
//                             node.querySelectorAll("[data-i18n]").forEach(el => {
//                                 translateElement(el)
//                             })
//                         }
//                     })
//                 } else if (mutation.type === "characterData" && mutation.target.parentElement) {
//                     const parentElement = mutation.target.parentElement
//                     if (parentElement.hasAttribute("data-i18n")) {
//                         translateElement(parentElement)
//                     }
//                 }
//             })
//         }
//
//         if (!observerRef.current) {
//             observerRef.current = new MutationObserver(observerCallback)
//             observerRef.current.observe(document.body, {
//                 childList: true,
//                 subtree: true,
//                 characterData: true,
//             })
//             console.log("MutationObserver set up")
//         }
//
//         applyTranslations()
//
//         return () => {
//             console.log("Cleaning up translations")
//             if (observerRef.current) {
//                 observerRef.current.disconnect()
//                 observerRef.current = null
//             }
//             if (timeoutRef.current) {
//                 clearTimeout(timeoutRef.current)
//             }
//         }
//     }, [applyTranslations, translateElement])
//
//     return null
// }
//
// export const withTranslation = <P extends object>(WrappedComponent: React.ComponentType<P>, jsonPath: string) => {
//     return function WithTranslation(props: P) {
//         useTranslations(jsonPath)
//         return <WrappedComponent {...props} />
//     }
// }
