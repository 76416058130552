import katex from "katex"

export function decodeHTMLEntities(text: string): string {
    const textarea = document.createElement("textarea")
    textarea.innerHTML = text
    return textarea.value
}

export function fixLatexSyntax(latex: string): string {
    let fixed = decodeHTMLEntities(latex)
    fixed = fixed.trim()
    fixed = fixed.replace(/\\+$/g, "")
    fixed = fixed.replace(/&amp;/g, "&")
    fixed = fixed.replace(" \\\n", " \\\\\n").trim()
    fixed = fixed.replace(/\s?\\+$/, "")
    fixed = fixed.replace(/(^\s+|\s+$)/g, "")
    return fixed
}

export function renderLatex(html: string): string {
    const latexRegex = /\\\[([\s\S]*?)]|\\\(([\s\S]*?)\\\)/g
    const citationsRegex = /\[[^\[\]:\n]+\.[a-z]{1,6}:[a-f0-9]{24}:\d+:\d\.\d+]/g
    const imageCitationsRegex = /\[[^\[\]:\n]+\.pdf:[a-f0-9]{24}:\d+;\d+]/g

    let res = html.replace(latexRegex, (match, squareBracketLatex, parenthesesLatex) => {
        // Ensure it's not a citation or image citation
        if (match.trim().match(citationsRegex) || match.trim().match(imageCitationsRegex)) {
            return match
        }

        const latexContent = squareBracketLatex || parenthesesLatex
        if (!latexContent) return match

        try {
            const fixedLatex = fixLatexSyntax(latexContent)
            return katex.renderToString(fixedLatex, {
                displayMode: !!squareBracketLatex,
                throwOnError: true,
                strict: false,
                output: "mathml",
            })
        } catch (err) {
            console.error("Error rendering LaTeX:", match, err)
            return `<span class="katex-error" title="LaTeX rendering error">${match}</span>`
        }
    })
    res = res.replace(/<span class="katex">/g, '<span class="katex" style="padding: 10px; font-size: 1.33em">')
    // res = res.replace(/\\<span/g, "<div style='width: 15px'> </div><span")

    return res
}
