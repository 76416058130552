import { useMutation, useQuery } from "react-query"
import { useDispatch } from "react-redux"

import { createCheckoutSession, getSubscription, getSubscriptionsPlans } from "@/api"
import { setSubscriptionPlanStatus } from "@/slices/chatSlice"

export const useSubscriptions = (fetchPlans = false) => {
    const dispatch = useDispatch()

    // Fetch the active subscription
    const subscriptionQuery = useQuery("subscription", getSubscription, {
        refetchOnWindowFocus: false, // Prevent refetch when window focus is regained
        retry: false, // Prevent retry on failure
        onSuccess: data => {
            const planName = data?.plan ? data.plan.name : undefined
            const { status } = data || {}
            dispatch(setSubscriptionPlanStatus({ planName, status }))
        },
    })

    // Fetch subscription plans conditionally
    const subscriptionPlansQuery = useQuery("subscriptionPlans", getSubscriptionsPlans, {
        enabled: fetchPlans, // Only fetch if fetchPlans is true
        refetchOnWindowFocus: false, // Prevent refetch when window focus is regained
        retry: false, // Prevent retry on failure
    })

    const purchaseSubscriptionPlan = useMutation((planId: string) => createCheckoutSession(planId))

    return {
        subscription: subscriptionQuery.data,
        subscriptionPlans: fetchPlans ? subscriptionPlansQuery.data : undefined,
        isLoading: subscriptionQuery.isLoading || (fetchPlans && subscriptionPlansQuery.isLoading),
        error: subscriptionQuery.error || (fetchPlans && subscriptionPlansQuery.error),
        purchaseSubscriptionPlan,
    }
}
