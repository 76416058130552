// api.ts
import axios from "axios"

export interface FileSchemaResponse {
    id: string
    file_name: string
    metadata: Record<string, any>
    ai_tags: Record<string, any>
    manual_tags: Record<string, any>
    summary: string
    folder_id?: string
    uploaded_by_user_id?: string
    file_size?: number
    uploaded_time_utc?: string
    updated_time_utc?: string
    number_of_pages?: number
    score: string
    file_source?: string
    connector_name?: string
    is_folder?: boolean
    file_folder?: string
}

export async function getFiles(path?: string): Promise<Array<FileSchemaResponse>> {
    try {
        const response = await axios.get("/files", {
            params: {
                folder_name: path,
            },
        })
        return response.data
    } catch (error) {
        throw new Error("Could not get files")
    }
}

export async function getFileInfo(id: string, fileName: string | null): Promise<FileSchemaResponse> {
    try {
        let url = `/files/${id}`
        if (fileName) {
            url += `?file_name=${fileName}`
        }
        const response = await axios.get(url)
        return response.data
    } catch (error) {
        throw new Error("Could not get file info")
    }
}

export type FileUpdateSchema = {
    file_name: string
    id: string
    manual_tags: any
}

export async function updateFile(id: string, params: FileUpdateSchema): Promise<boolean> {
    try {
        const response = await axios.patch(`/files/${id}`, { ...params })

        if (response.status < 200 || response.status >= 300) {
            throw new Error("Could not update file")
        }

        return true
    } catch (error) {
        if (axios.isAxiosError(error) && error.response && error.response.status === 403) {
            throw new Error(error.response.data.detail || "Could not update file")
        }

        console.error("Error updating file:", error)
        throw new Error("Could not update file")
    }
}

export async function deleteFiles(ids: Array<string>) {
    try {
        const response = await axios.delete("/files/delete", { data: ids })

        if (response.status < 200 || response.status >= 300) {
            throw new Error("File deletion failed")
        }

        return {
            status: response.status,
            data: response.data,
        }
    } catch (error) {
        throw new Error("File deletion failed")
    }
}

export async function downloadFile(
    fileId: string,
    onDownloadProgress?: (progressEvent: any) => void,
    path?: string,
    downloadSource: boolean = false
): Promise<{ blob: Blob; filename: string }> {
    try {
        const url = `/files/download/${fileId}${downloadSource ? "?download_source=true" : ""}`

        const response = await axios.get(url, {
            responseType: "blob",
            onDownloadProgress,
        })

        if (!response.data) {
            throw new Error("File download failed")
        }

        // Extract filename from Content-Disposition header
        const contentDisposition = response.headers["content-disposition"]
        let filename = path // fallback to original path
        if (contentDisposition) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "")
            }
        }

        return {
            blob: response.data,
            filename: filename || "",
        }
    } catch (error) {
        throw new Error("File download failed")
    }
}

export type Question = {
    _id: any
    number_of_clicks: number
    text: string
    timestamp: any
}

export async function getTopQuestions(): Promise<Array<Question>> {
    try {
        const response = await axios.get("chat/top_questions")

        if (!response.data) {
            throw new Error("Could not get top questions")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get top questions")
    }
}

interface LastQuestion {
    _id: string
    text: string
    timestamp: Date
    number_of_clicks: number
    created_by_user_id: string
}

export const getLastQuestions = async (): Promise<Array<LastQuestion>> => {
    const { data } = await axios.get("chat/last_questions", {
        method: "GET",
    })

    if (!data) {
        throw new Error("Could not get last questions")
    }

    return data
}

export async function searchFiles({
    query,
    search_profile,
    file_format,
    file_source,
    date_from,
    date_to,
    file_size_from,
    file_size_to,
}: {
    query: string
    search_profile: string
    file_format: string[]
    file_source: string[]
    date_from: Date | null
    date_to: Date | null
    file_size_from: number
    file_size_to: number
}): Promise<any> {
    try {
        const toUTCMidnightString = (date: Date): string => {
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
            return utcDate.toISOString()
        }

        const requestPayload = {
            query: query,
            search_profile: search_profile.toLowerCase(),
            file_format: file_format,
            file_source: file_source,
            date_from: date_from ? toUTCMidnightString(date_from) : null,
            date_to: date_to ? toUTCMidnightString(date_to) : null,
            file_size_from: file_size_from,
            file_size_to: file_size_to,
        }

        const response = await axios.post("/files/search_files", requestPayload)

        if (!response.data) {
            throw new Error("Could not get files")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get files")
    }
}

export async function searchConversations({
    query,
    search_profile,
    date_from,
    date_to,
}: {
    query: string
    search_profile: string
    date_from: Date | null
    date_to: Date | null
}): Promise<any> {
    try {
        const response = await axios.post("/chat/search_conversations", {
            query: query,
            search_profile: search_profile.toLowerCase(),
            date_from: date_from,
            date_to: date_to,
        })

        if (!response.data) {
            throw new Error("Could not get conversations")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get conversations")
    }
}

// Cache object to store source URLs. will be cleared on page reload
const cache: { [fileId: string]: { url: string; timestamp: number } } = {}

const CACHE_EXPIRATION_TIME = 3600000 // 1 hour in milliseconds

export async function getSourceUrl(fileId: string): Promise<string> {
    try {
        const now = Date.now()

        // Check if the fileId is in cache and if it's still valid
        if (cache[fileId] && now - cache[fileId].timestamp < CACHE_EXPIRATION_TIME) {
            console.log("Returning cached source URL")
            return cache[fileId].url
        }

        // If not in cache or expired, fetch from the server
        console.log("Fetching source URL from server for fileId", fileId)
        const url = `/files/get_source_url/${fileId}`
        const response = await axios.get(url)
        const sourceUrl = response.data.source_url

        // Cache the response
        cache[fileId] = {
            url: sourceUrl,
            timestamp: now,
        }

        return sourceUrl
    } catch (error) {
        console.error("Could not get source URL", error)
        throw new Error("Could not get source URL")
    }
}
