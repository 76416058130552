import CloudQueueIcon from "@mui/icons-material/CloudQueue"
import { DatabaseConnector, EmailConnector } from "@/assets"

export type ConnectorTypeKey = keyof typeof CONNECTOR_DEFINITIONS
export type ConnectorDefinition = (typeof CONNECTOR_DEFINITIONS)[ConnectorTypeKey]
export type ConnectorType = ConnectorDefinition["type"]

export const CONNECTOR_DEFINITIONS = {
    GOOGLE_DRIVE: { type: "google_drive", label: "Google Drive" },
    ONE_DRIVE: { type: "onedrive", label: "OneDrive" },
    OUTLOOK: { type: "outlook", label: "Outlook" },
    IMAP: { type: "imap", label: "Email (IMAP)" },
    GMAIL: { type: "gmail", label: "Gmail" },
    ICLOUD_MAIL: { type: "icloud mail", label: "iCloud Mail" },
    ORACLE_DB: { type: "oracledb", label: "Oracle DB" },
    POSTGRESQL: { type: "postgresql", label: "PostgreSQL" },
    MYSQL: { type: "mysql", label: "MySQL" },
} as const

export const CONNECTOR_DEFINITION_BY_TYPE = Object.fromEntries(
    Object.values(CONNECTOR_DEFINITIONS).map(def => [def.type, def])
) as Record<ConnectorType, ConnectorDefinition>

export const getConnectorLabelByType = (type: ConnectorType): string | undefined =>
    CONNECTOR_DEFINITION_BY_TYPE[type]?.label

export const CONNECTOR_TYPES = Object.fromEntries(
    Object.entries(CONNECTOR_DEFINITIONS).map(([key, def]) => [key, def.type])
) as Record<ConnectorTypeKey, ConnectorType>
export const EmailConnectorTypes = [
    CONNECTOR_TYPES.GMAIL,
    CONNECTOR_TYPES.ICLOUD_MAIL,
    CONNECTOR_TYPES.IMAP,
    CONNECTOR_TYPES.OUTLOOK,
] as const

export const DatabaseConnectorTypes = [
    CONNECTOR_TYPES.ORACLE_DB,
    CONNECTOR_TYPES.POSTGRESQL,
    CONNECTOR_TYPES.MYSQL,
] as const

interface ConnectorCategory {
    label: string
    types: ConnectorType[]
}

export const CONNECTOR_CATEGORY_DEFINITIONS: Record<string, ConnectorCategory> = {
    CLOUD_STORAGE: {
        label: "Cloud Storage",
        types: [CONNECTOR_TYPES.GOOGLE_DRIVE, CONNECTOR_TYPES.ONE_DRIVE],
    },
    EMAIL_SERVICES: {
        label: "Email Services",
        types: [CONNECTOR_TYPES.GMAIL, CONNECTOR_TYPES.ICLOUD_MAIL, CONNECTOR_TYPES.IMAP, CONNECTOR_TYPES.OUTLOOK],
    },
    DATABASES: {
        label: "Databases",
        types: [CONNECTOR_TYPES.ORACLE_DB, CONNECTOR_TYPES.POSTGRESQL, CONNECTOR_TYPES.MYSQL],
    },
}
export const CONNECTOR_CATEGORIES: ConnectorCategory[] = Object.values(CONNECTOR_CATEGORY_DEFINITIONS)

export const iconStyle = { marginRight: "10px", marginTop: "0px" }
export const connectorIcons: { [key: string]: { icon: JSX.Element } } = {
    google_drive: { icon: <CloudQueueIcon style={iconStyle} /> },
    onedrive: { icon: <CloudQueueIcon style={iconStyle} /> },
    outlook: {
        icon: <img style={{ ...iconStyle, width: "24px", height: "24px" }} src={EmailConnector} />,
    },
    imap: {
        icon: <img style={{ ...iconStyle, width: "24px", height: "24px" }} src={EmailConnector} />,
    },
    oracledb: {
        icon: <img style={{ ...iconStyle, width: "24px", height: "24px" }} src={DatabaseConnector} />,
    },
}

export const getDefaultDatabasePort = (connectorType: ConnectorType): string => {
    switch (connectorType) {
        case "postgresql":
            return "5432"
        case "mysql":
            return "3306"
        case "oracledb":
            return "1521"
        default:
            return ""
    }
}
