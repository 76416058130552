import React from "react"
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Box,
    useTheme,
    useMediaQuery,
    Checkbox,
    FormControlLabel,
    ListItem,
    List,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { ModalProps } from "./index"
import { useSettings } from "@/components/Settings/useSettings"
import "./welcome.css"
import { UserTypes } from "@/api"
import { styled } from "@mui/material/styles"
import { TbUserCheck as BeginnerIcon } from "react-icons/tb"
import { GrUserSettings as ExpertIcon } from "react-icons/gr"

interface WelcomeModalProps extends ModalProps {
    onDoNotShowAgainChange?: (doNotShowAgain: boolean) => void
}

const StyledButton = styled(Button)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    border: `2px solid ${theme.palette.mode === "dark" ? theme.palette.grey[700] : theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    transition: "transform 0.2s, box-shadow 0.2s, border-color 0.2s",
    "&:hover": {
        transform: "scale(1.03)",
        boxShadow: theme.shadows[4],
        borderColor: theme.palette.primary.main,
    },
    "& .MuiButton-startIcon": {
        marginBottom: theme.spacing(1),
        fontSize: "3rem",
        marginRight: 0,
    },
}))

const beginnerPoints = [
    "Designed for ease: perfect for first-time users.",
    "Focus on results, without the setup.",
    "AI manages settings and documents for you.",
]

const expertPoints = [
    "Customize settings and manually select documents.",
    "Unlock the full power of our platform.",
    "Adjust settings and parameters to perfectly match your needs.",
]

const WelcomeModal: React.FC<WelcomeModalProps> = ({ open, close, title, content }) => {
    const { onUserTypeChange, showWelcomeMessage, onShowWelcomeMessageChange } = useSettings()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const handleUserTypeSelection = (userType: UserTypes) => {
        onUserTypeChange({ target: { value: JSON.stringify({ key: userType }) } } as any)
        close()
    }

    const handleDoNotShowAgainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onShowWelcomeMessageChange(!event.target.checked)
    }

    const listItemStyle = {
        display: "list-item",
        listStyleType: "disc",
        paddingBottom: "0px",
        paddingTop: "0px",

        "&::marker": {
            color: theme.palette.text.secondary,
            fontSize: "1.5em",
        },
    }

    const modeContentStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "column" as const,
        // alignItems: "flex-start",
        alignItems: "center",
    }

    const descriptionStyle = {
        marginTop: 1,
        paddingLeft: "10px",
        paddingRight: "8px",
        textAlign: "left" as const,
    }

    const bulletPointsContainerStyle = {
        width: "100%",
        paddingLeft: theme.spacing(5),
        marginTop: 1,
    }

    return (
        <Dialog
            open={open}
            onClose={close}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "550px",
                    maxWidth: "550px",
                },
            }}>
            <DialogTitle
                sx={{
                    backgroundColor: "white",
                    padding: "12px 18px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "8px",
                }}>
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", pt: 1 }}>
                    <Typography variant="h6" gutterBottom>
                        Welcome to Aidocr! 🎉
                    </Typography>
                </Box>
                <IconButton onClick={close} sx={{ transform: "translate(5px, -5px)" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ overflowY: "auto" }}>
                <Typography variant="body1" align="center" paragraph color="textSecondary">
                    Personalize your experience by choosing the mode that suits your workflow. You can change this later
                    in the user menu.
                </Typography>

                <Box mt={isMobile ? 2 : 3}>
                    <StyledButton
                        variant="outlined"
                        startIcon={<BeginnerIcon color="#2e7d32" size="29px" />}
                        onClick={() => handleUserTypeSelection(UserTypes.Beginner)}>
                        <Typography variant="h6" fontFamily="'Comfortaa', sans-serif">
                            Guided Mode
                        </Typography>
                        <div style={modeContentStyle}>
                            <Typography variant="body1" color="textSecondary" sx={descriptionStyle}>
                                For users seeking a simple and fast experience.
                            </Typography>
                            {/*<div style={bulletPointsContainerStyle}>*/}
                            {/*    <List disablePadding>*/}
                            {/*        {beginnerPoints.map((point, index) => (*/}
                            {/*            <ListItem key={index} sx={listItemStyle}>*/}
                            {/*                <Typography color="text.secondary" fontSize="14px" pl={0} ml={-1}>*/}
                            {/*                    {point}*/}
                            {/*                </Typography>*/}
                            {/*            </ListItem>*/}
                            {/*        ))}*/}
                            {/*    </List>*/}
                            {/*</div>*/}
                        </div>
                    </StyledButton>

                    <StyledButton
                        variant="outlined"
                        startIcon={<ExpertIcon size="26px" />}
                        onClick={() => handleUserTypeSelection(UserTypes.Expert)}>
                        <Typography variant="h6" fontFamily="'Comfortaa', sans-serif">
                            Advanced Mode
                        </Typography>
                        <div style={modeContentStyle}>
                            <Typography variant="body1" color="textSecondary" sx={descriptionStyle}>
                                For users who want full control and customization.
                            </Typography>
                            {/*<div style={bulletPointsContainerStyle}>*/}
                            {/*    <List disablePadding>*/}
                            {/*        {expertPoints.map((point, index) => (*/}
                            {/*            <ListItem key={index} sx={listItemStyle}>*/}
                            {/*                <Typography color="text.secondary" fontSize="14px" pl={0} ml={-1}>*/}
                            {/*                    {point}*/}
                            {/*                </Typography>*/}
                            {/*            </ListItem>*/}
                            {/*        ))}*/}
                            {/*    </List>*/}
                            {/*</div>*/}
                        </div>
                    </StyledButton>
                </Box>

                <div style={{ display: "flex", justifyContent: "center", marginTop: "15px", marginBottom: "-5px" }}>
                    <FormControlLabel
                        control={<Checkbox checked={!showWelcomeMessage} onChange={handleDoNotShowAgainChange} />}
                        label="Do not show this message again"
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default WelcomeModal
